@font-face {
  font-family: 'Golos';
  src: url('assets/fonts/Golos-Text_Black.ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Golos';
  src: url('assets/fonts/Golos-Text_Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Golos';
  src: url('assets/fonts/Golos-Text_DemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Golos';
  src: url('assets/fonts/Golos-Text_Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Golos';
  src: url('assets/fonts/Golos-Text_Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Golos-Text_VF';
  font-weight: 700;
  src: url('assets/fonts/Golos-Text_VF.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Golos-Text_VF';
  font-weight: 900;
  src: url('assets/fonts/Golos-Text_VF.ttf');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Golos-Text_VF';
  font-weight: 500;
  src: url('assets/fonts/Golos-Text_VF.ttf');
  font-display: swap;
}
